import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomies } from "../../redux/actions/taxonomyA";
import Loading from "../components/Loading";
import Submit from "../components/Submit";
import SelectOptions from "./SelectOptions";

const CreateSKU = () => {
    const taxo = useSelector((state) => state.taxonomyR);
    const vari = useSelector((state) => state.variationR);
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [data, setData] = useState(1);
    // const elements = [];
    const [tot,setTot] = useState(0);
    const [elements,setElements] = useState({
        name: []
    })

    useEffect(() => {
        if (!taxo.taxoGet) {
            dispatch(getTaxonomies());
        }
    }, [dispatch, taxo.taxoGet]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setData(elements);
        setStep(2);
    };

    const handleChange = (event) => {
        if (event.target.checked === true) {
            setElements({
                ...elements,
                name: elements.name.concat(event.target.value)
            })
            setTot(tot+1);
        } else {
             // eslint-disable-next-line
            var arreglo = elements.name.filter( (item) => item != event.target.value)
            setElements({
                ...elements,
                name: arreglo
            })
            setTot(tot-1)
        }
    };

    const resetSteps = () => {
        setStep(1);
        setTot(0)
        setElements({
            name: []
        })
    }
    return (
        <div>
            {taxo.taxoLoad ? (
                <Loading />
            ) : (
                <Fragment>
                    {step === 1 && (
                        <form action="" onSubmit={handleSubmit}>
                            <div className="mt-5">
                                <h3 className="font-bold mb-1 text-lg text-gray-700">
                                    Select the attributes to configure
                                </h3>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Name</th>
                                            <th>Display Name</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(taxo.taxonomies).map(
                                            ([key, item]) => (
                                                <tr key={key}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            id={`taxonomy_id_${item.id}`}
                                                            name="taxonomy_id"
                                                            className="w-5 h-5"
                                                            value={item.id}
                                                            onChange={
                                                                handleChange
                                                            }
                                                        />
                                                    </td>
                                                    <td>
                                                        <label
                                                            className="cursor-pointer block"
                                                            htmlFor={`taxonomy_id_${item.id}`}
                                                        >
                                                            {item.name}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label
                                                            className="cursor-pointer block"
                                                            htmlFor={`taxonomy_id_${item.id}`}
                                                        >
                                                            {item.display_name}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label
                                                            className="cursor-pointer block"
                                                            htmlFor={`taxonomy_id_${item.id}`}
                                                        >
                                                            {item.type}
                                                        </label>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex justify-center mt-5">
                                {tot > 0 &&
                                    <Submit
                                        status={vari.variationBtn}
                                        text="Next"
                                        icon="fa fa-arrow-right"
                                    />
                                }
                            </div>
                        </form>
                    )}
                    {step === 2 && (
                        <SelectOptions
                            attributes={data}
                            resetStep={resetSteps}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default CreateSKU;
