import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { productStore } from "../../redux/actions/productA";
import Breadcrumbs from "../components/Breadcrumbs";
import Submit from "../components/Submit";
import Success from "../components/Success";
import Error from "../components/Error";
import CancelBtn from "../components/CancelBtn";
import { getCategories } from "../../redux/actions/categoryA";
import Loading from "../components/Loading";
import Message from "../components/Message";
import api from '../../api';

const ProductCreate = () => {
    const prod = useSelector((state) => state.productR);
    const emptyValues = {
        name: "",
        description: "",
        type: "physical",
        weight: "",
        sku: "",
        price: "",
        category_id: "",
        page_title: "",
        meta_keywords: "",
        search_keywords: "",
        meta_description: "",
    }
    const [data, setData] = useState(emptyValues);
    const cate = useSelector((state) => state.categoryR);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!cate.catGet) {
            dispatch(getCategories());
        }
    }, [dispatch, cate.catGet]);

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const uploadImages = async (event) => {
        var formData = new FormData();
        var imagedata = event.target.files[0];
        formData.append('image_file', imagedata);
        const response = await api.post(
            "/product/upload-image",
        formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        let file_name = response.data;
        // console.log(response.data);
        setData({
            ...data,
            image_file: file_name
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log(data);
        dispatch(productStore(data));
    };

    useEffect(() => {
        if (prod.productSuccess) {
            setData(emptyValues);
        }
    }, [prod.productSuccess, emptyValues]);

    return (
        <div className="">
            <Breadcrumbs text="Products / Create" />
            <h1 className="h1 lg:w-8/12 mt-10">Create new product</h1>
            <div className="w-11/12 lg:w-1/2 m-auto">
                <Error
                    status={prod.productErrStatus}
                    errors={prod.productErrors}
                    close="PRODUCT_ERR_CLOSE"
                />
                <Success
                    status={prod.productSuccess}
                    message="Operation completed successfully."
                    close="PRODUCT_SUC_CLOSE"
                />
            </div>
            {cate.catLoad ? (
                <Loading />
            ) : (
                <Fragment>
                    {cate.categories.length === 0 ? (
                        <div className="w-full lg:w-4/12 m-auto mt-10">
                            <Message text="You should add at least one category to continue" />
                        </div>
                    ) : (
                        <form
                            action=""
                            onSubmit={handleSubmit}
                            className="form lg:w-6/12 animate__animated animate__fadeIn animate__faster"
                            autoComplete="off"
                        >
                            <div className="flex">
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Name:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="name"
                                        onChange={handleChange}
                                        value={data.name}
                                    />
                                </div>
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Description:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="description"
                                        onChange={handleChange}
                                        value={data.description}
                                    />
                                </div>
                            </div>

                            <div className="flex mt-3">
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Type:
                                    </label>
                                    <select
                                        name="type"
                                        id="type"
                                        className="select"
                                        onChange={handleChange}
                                        value={data.type}
                                    >
                                        <option value="physical">Physical</option>
                                        <option value="digital">Digital</option>
                                    </select>
                                </div>
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Weight:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="weight"
                                        onChange={handleChange}
                                        value={data.weight}
                                    />
                                </div>
                            </div>

                            <div className="flex mt-3">
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Sku:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="sku"
                                        onChange={handleChange}
                                        value={data.sku}
                                    />
                                </div>
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Price:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="price"
                                        onChange={handleChange}
                                        value={data.price}
                                    />
                                </div>
                            </div>

                            <div className="flex mt-3">
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Category:
                                    </label>
                                    <select
                                        name="category_id"
                                        id="category_id"
                                        className="select"
                                        onChange={handleChange}
                                        value={data.category_id}
                                    >
                                        <option value=""></option>
                                        {Object.entries(cate.categories).map(
                                            ([key, item]) => (
                                                <option
                                                    key={key}
                                                    value={`${item.id}`}
                                                >
                                                    {item.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Image: <span className="text-gray-500 text-xs">Recommend: 500px x 500px</span>
                                    </label>
                                    <input
                                        type="file"
                                        className="input w-full"
                                        name="image_file"
                                        id="image_file"
                                        onChange={uploadImages}
                                    />
                                </div>
                                {/* <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Page Title:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="page_title"
                                        onChange={handleChange}
                                        value={data.page_title}
                                    />
                                </div> */}
                            </div>

                            <div className="flex mt-3">
                                
                                {/* <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Meta Description:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="meta_description"
                                        onChange={handleChange}
                                        value={data.meta_description}
                                    />
                                </div> */}
                            </div>

                            {/* <div className="flex mt-3">
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Search Keywords:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="search_keywords"
                                        onChange={handleChange}
                                        value={data.search_keywords}
                                    />
                                </div>
                                <div className="flex flex-col w-1/2 px-5">
                                    <label
                                        htmlFor=""
                                        className="mr-2 w-full label"
                                    >
                                        Meta Keywords:
                                    </label>
                                    <input
                                        type="text"
                                        className="input w-full"
                                        name="meta_keywords"
                                        onChange={handleChange}
                                        value={data.meta_keywords}
                                    />
                                </div>
                            </div> */}

                            <div className="flex justify-center mt-5">
                                <CancelBtn
                                    text="Cancel"
                                    icon="fa fa-arrow-left"
                                    url="/products"
                                />
                                <Submit
                                    status={prod.productBtn}
                                    text="Create Product & Add Variants"
                                    icon="fa fa-save"
                                />
                            </div>
                        </form>
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default ProductCreate;
