import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import AddBtn from "../components/AddBtn";
import Loading from "../components/Loading";
import { BrowserRouter, Switch, Route, useParams } from "react-router-dom";
import CreateSKU from './CreateSKU'
import ListSKU from './ListSKU'

const VariationMain = () => {
    const prod = useSelector((state) => state.productR);
    // let { url, path } = useRouteMatch()
    let { key } = useParams();
    return (
        <BrowserRouter>
            <h1 className="h1 lg:w-8/12">Edit Product Variations</h1>
            <div className="w-full lg:px-10">
                {prod.productLoad ? (
                    <Loading />
                ) : (
                    <Fragment>
                        <div className="flex justify-between items-start mt-10">
                            <h2 className="text-2xl underline self-start">
                                {prod.product.name}
                            </h2>
                            <div>
                                <AddBtn
                                    text="Create SKU's"
                                    icon="fa fa-plus"
                                    url={`/products/${key}/edit/variations/create-skus`}
                                />
                                <AddBtn
                                    text="List"
                                    icon="fa fa-list"
                                    url={`/products/${key}/edit/variations/list-skus`}
                                />
                            </div>
                        </div>
                        <Switch>
                            <Route exact path="/products/:key/edit/variations/create-skus" component={CreateSKU} />
                            <Route exact path="/products/:key/edit/variations/list-skus" component={ListSKU} />
                        </Switch>
                    </Fragment>
                )}
            </div>
        </BrowserRouter>
    );
};

export default VariationMain;