import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTaxonomiesSelected } from "../../redux/actions/taxonomyA";
import { createVariations } from "../../redux/actions/variationA";
import Loading from "../components/Loading";
import NoRecords from "../components/NoRecords";

const SelectOptions = ({ attributes, resetStep }) => {
    const dispatch = useDispatch();
    const taxo = useSelector((state) => state.taxonomyR);
    const [data, setData] = useState({
        ids: attributes.name,
        elements: {

        }
    });

    useEffect(() => {
        dispatch(getTaxonomiesSelected(attributes));
    }, [dispatch, attributes]);

    const handleChange = (event) => {
        let element = event.target;
        const elementValue = element.value
        const id = element.id
        const taxonomyId = element.getAttribute("data-taxo-id");
        if (element.checked === true) {
            setData({
                ...data,
                elements: {
                    ...data.elements,
                    [id]: {
                        id: taxonomyId,
                        value: elementValue
                    }
                }
            })
        } else {
            delete data.elements[id];
        }
    };
    
    const handleSubmit = () => {
        // console.log(data)
        dispatch(createVariations(data))
    }

    return (
        <div className="mt-10">
            {taxo.taxoLoad ? (
                <Loading />
            ) : 
            // eslint-disable-next-line
            taxo.taxoSelected.length == 0 ? (
                <NoRecords />
            ) : (
                <Fragment>
                    {Object.entries(taxo.taxoSelected).map(
                        ([key, item]) => (
                            <div
                                key={key}
                                className="my-2 border border-gray-400 bg-gray-100 p-5 w-full lg:w-1/2 mx-auto"
                            >
                                <h3 className="text-gray-700 font-bold border-b border-gray-300 mb-3">
                                    {item.name}
                                </h3>
                                <div className="grid grid-cols-5 gap-y-8">
                                    {Object.entries(item.taxonomy_values).map(
                                        ([key_2, item_2]) => (
                                            <div
                                                key={`${key}_${key_2}`}
                                                className="flex justify-start items-center cursor-pointer"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`options_${item.id}_${item_2.id}`}
                                                    className="w-5 h-5 mr-2  cursor-pointer"
                                                    onChange={handleChange}
                                                    value={item_2.id}
                                                    data-taxo-id={item.id}
                                                />
                                                <label
                                                    className="cursor-pointer"
                                                    htmlFor={`options_${item.id}_${item_2.id}`}
                                                >
                                                    {item_2.label}
                                                </label>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )
                    )}
                    <div className="flex justify-center mt-10">
                        <button
                            type="button"
                            onClick={resetStep}
                            className="bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded"
                        >
                            <i className="fa fa-sync"></i> Reset
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="ml-5 bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded"
                        >
                            <i className="fa fa-arrow-right"></i> Next
                        </button>
                    </div>
                </Fragment>
            )}
        </div>
    );
};
export default SelectOptions;
