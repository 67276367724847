import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getCategories,
    catUpdate,
    getAllFromServer,
} from "../../redux/actions/categoryA";
import Breadcrumbs from "../components/Breadcrumbs";
import Submit from "../components/Submit";
import Success from "../components/Success";
import Error from "../components/Error";
import CancelBtn from "../components/CancelBtn";
import Loading from "../components/Loading";

const CatEdit = (props) => {
    const catkey = props.match.params.catkey;
    const cate = useSelector((state) => state.categoryR);
    const [data, setData] = useState({
        name: "",
        description: "",
        page_title: "",
        meta_keywords: "",
        search_keywords: "",
        meta_description: ""
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (!cate.catGet) {
            dispatch(getCategories());
        }
    }, [dispatch, cate.catGet]);


    useEffect(() => {
        if (cate.categories.length > 0) {
            dispatch(getAllFromServer(cate.categories[catkey].id));
        }
    }, [dispatch, cate.categories, catkey]);

    useEffect( () => {
        const category = Object.entries(cate.category);
        if(category.length > 0 && cate.categories.length > 0) {
            var meta_keywords = cate.category.meta_keywords.toString();
            setData({
                ...cate.category,
                meta_keywords: meta_keywords,
            })
        }
    },[cate.catLoad, cate.category, cate.categories])

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(catUpdate(data, catkey));
    };

    return (
        <div className="">
            <Breadcrumbs text="Categories / Modify" />
            <h1 className="h1 lg:w-8/12 mt-10">Edit category</h1>
            <div className="w-11/12 lg:w-1/2 m-auto">
                <Error
                    status={cate.catErrStatus}
                    errors={cate.catErrors}
                    close="CAT_ERR_CLOSE"
                />
                <Success
                    status={cate.catSuccess}
                    message="Operation completed successfully."
                    close="CAT_SUC_CLOSE"
                />
            </div>
            {cate.catLoad ? (
                <Loading />
            ) : (
                <Fragment>
                    <form
                        action=""
                        onSubmit={handleSubmit}
                        className="form lg:w-4/12 animate__animated animate__fadeIn animate__faster"
                        autoComplete="off"
                    >
                        <div className="flex flex-col">
                            <label htmlFor="" className="mr-2 w-full label">
                                Name:
                            </label>
                            <input
                                type="text"
                                className="input w-full"
                                name="name"
                                onChange={handleChange}
                                value={data.name}
                            />
                        </div>
                        {/* <div className="flex flex-col mt-2">
                            <label htmlFor="" className="mr-2 w-full label">
                                Description:
                            </label>
                            <input
                                type="text"
                                className="input w-full"
                                name="description"
                                onChange={handleChange}
                                value={data.description}
                            />
                        </div>
                        <div className="flex flex-col mt-2">
                            <label htmlFor="" className="mr-2 w-full label">
                                Page Title:
                            </label>
                            <input
                                type="text"
                                className="input w-full"
                                name="page_title"
                                onChange={handleChange}
                                value={data.page_title}
                            />
                        </div>
                        <div className="flex flex-col mt-2">
                            <label htmlFor="" className="mr-2 w-full label">
                                Meta Keywords:
                            </label>
                            <input
                                type="text"
                                className="input w-full"
                                name="meta_keywords"
                                onChange={handleChange}
                                value={data.meta_keywords}
                            />
                        </div>
                        <div className="flex flex-col mt-2">
                            <label htmlFor="" className="mr-2 w-full label">
                                Search Keywords:
                            </label>
                            <input
                                type="text"
                                className="input w-full"
                                name="search_keywords" 
                                onChange={handleChange}
                                value={data.search_keywords}
                            />
                        </div>
                        <div className="flex flex-col mt-2">
                            <label htmlFor="" className="mr-2 w-full label">
                                Meta Description:
                            </label>
                            <input
                                type="text"
                                className="input w-full"
                                name="meta_description"
                                onChange={handleChange}
                                value={data.meta_description}
                            />
                        </div> */}
                        <div className="flex justify-center mt-5">
                            <CancelBtn
                                text="Cancel"
                                icon="fa fa-arrow-left"
                                url="/categories"
                            />
                            <Submit
                                status={cate.catBtn}
                                text="Save changes"
                                icon="fa fa-save"
                            />
                        </div>
                    </form>
                </Fragment>
            )}
        </div>
    );
};

export default CatEdit;
