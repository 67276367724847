
// export const apiURL = 'http://localhost:8000/api';
// export const authURL = 'http://localhost:8000/oauth';
// export const grant_type = "password";
// export const client_id = 2;
// export const client_secret = "OpJShhsfnFMWIukKbeLy0fhfDJXyWaZgA0sEQCjV";
// export const scope = "";

export const apiURL = 'https://outfitbuilderapi.nuethic.com/api';
export const authURL = 'https://outfitbuilderapi.nuethic.com/oauth';
export const grant_type = "password";
export const client_id = 2;
export const client_secret = "OsSsA3FcxabSSHtgzzH0hQVPRkYtHQT6ZoTgkGI7";
export const scope = "";

export const verifyLogin = () => {
    let loggedIn = true;
    const access_token = localStorage.getItem('access_token');
    if (!access_token) {
        loggedIn = false;
    } 
    return loggedIn;
}