// import axios from "axios";
// const RmUrl = "https://rickandmortyapi.com/api/episode?page=2";
import api from "../../api";

export const getData = () => async (dispatch) => {
    dispatch({ type: "loading" });
    try {
        const response = await api.get("/license");
        //console.log("response", response.data.results);
        if (response.status === 200) {
            dispatch({
                type: "results",
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};
export const getDataDetails = (id) => async (dispatch) => {
    dispatch({ type: "loading_details" });
    try {
        const response = await api.get(`/license/${id}`);
        //console.log(response);
        if (response.status === 200) {
            dispatch({
                type: "result",
                payload: response.data[0],
            });
        }
    } catch(error) {
        console.log(error)
    }
};
