const initialState = {
    productBtn: false,
    productLoad: false,
    products: [],
    productGet: false,
    productGetServer: false,
    productErrStatus: false,
    productErrors: {},
    productSuccess: true,
    product: [],
    loaded: false,
    loading: false,
    all_data: [],
    removeSuccess: false
}

export default (state = initialState, action) => {
    switch(action.type) {
        case 'PRODUCT_BTN': {
            return {
                ...state,
                productBtn: true
            }
        }
        case 'PRODUCT_BTN_OFF': {
            return {
                ...state,
                productBtn: false
            }
        }
        case 'PRODUCT_LOAD': {
            return {
                ...state,
                productLoad: true
            }
        }

        case 'PRODUCT_RESET_ALERTS': {
            return {
                ...state,
                productBtn: false,
                productSuccess: false,
                productErrStatus: false,
            }
        }
        case 'PRODUCT_REFRESH_DATA': {
            return {
                ...state,
                productGet: false
            }
        }
        case 'PRODUCT_GET_ALL': {
            return {
                ...state,
                productGet: true,
                productLoad: false,
                products: action.payload
            }
        }
        case 'PRODUCT_ERR': {
            return {
                ...state,
                productErrStatus: true,
                productErrors: action.payload,
                productBtn: false,
                productSuccess: false
            }
        }

        case 'PRODUCT_ERR_CLOSE': {
            return {
                ...state,
                productErrStatus: false
            }
        }

        case 'PRODUCT_SUC_CLOSE': {
            return {
                ...state,
                productSuccess: false
            }
        }

        case 'PRODUCT_ADD': {
            return {
                ...state,
                productBtn: false,
                productSuccess: true,
                productErrStatus: false,
                // products: state.products.concat(action.payload)
                productGet: false
            }
        }
        case 'PRODUCT_EDIT': {
            return {
                ...state,
                productSuccess: true,
                productErrStatus: false,
                productBtn: false,
                product: action.payload,
                productGet: false
            }
        }
        case 'PRODUCT_REMOVE': {
            // var arreglo = state.products.filter( item => item.id !== action.payload );
            return {
                ...state,
                productSuccess: true,
                productErrStatus: false,
                productBtn: false,
                // products: arreglo
                productGet: false
            }
        }
        case 'PRODUCT_FROM_SERVER': {
            return {
                ...state,
                productLoad: false,
                product: action.payload,
                
            }
        }
        case 'PRODUCT_SERVER_LOADING': {
            return {
                ...state,
                loading: true,
            }
        }
        case 'PRODUCT_SERVER_ALL': {
            return {
                ...state,
                loading: false,
                all_data: action.payload,
                loaded: true
            }
        }
        case 'PRODUCT_ADDED_FROM_SERVER': {
            return {
                ...state,
                loading: false,
                productGet: false
            }
        }
        case 'PRODUCT_ADD_FROM_SERVER_ERROR': {
            return  {
                ...state,
                loading: false,
                productErrStatus: true,
                productErrors: action.payload
            }
        }
        case 'PRODUCT_REMOVE_SUCCESS': {
            return {
                ...state,
                removeSuccess: true,
                productGet: false
            }
        }
        case 'RESTART_REMOVE_SUCCESS': {
            return {
                ...state,
                removeSuccess: false,
            }
        }
        default: return state;
    }
}