import api from '../../api'

export const getMixValues = (product_id) => async dispatch => {
    try {
        const response = await api.get(`/outfit-builder/get/${product_id}`);
        dispatch({
            type:'OB_GET_SELECTED',
            payload: response.data
        })
    } catch(error) {
        console.log(error)
    }
}

export const createMix = (data) => async dispatch => {
    try {
        await api.post('/outfit-builder/create',data)
        const response2 = await api.get(`/outfit-builder/get/${data.product_id}`);
        dispatch({
            type:'OB_GET_SELECTED',
            payload: response2.data
        })
    } catch (error) {
        console.log(error)
    }
}