import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TaxoStore } from "../../redux/actions/taxonomyA";
import Breadcrumbs from "../components/Breadcrumbs";
import Submit from "../components/Submit";
import Success from "../components/Success";
import Error from "../components/Error";
import CancelBtn from "../components/CancelBtn";
import { Redirect } from 'react-router-dom';

const TaxoCreate = () => {
    const taxos = useSelector((state) => state.taxonomyR);
    const [data, setData] = useState({
        name: "",
        display_name: "",
        type: "radio_buttons",
    });
    const [counterArray, setCounterArray] = useState(0);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(TaxoStore(data));
    };

    useEffect( () => {
        if(taxos.taxoSuccess) {
            setData({
                name: "",
                display_name: "",
                type: "radio_buttons"
            })
        }
    },[taxos.taxoSuccess]);

    useEffect( () => {
        if(taxos.taxoSuccess) {
            let lastKey = taxos.taxonomies.length-1;
            setCounterArray(lastKey);
        }
    },[taxos.taxoSuccess,taxos.taxonomies])

    return (
        <div className="">
            {counterArray !== 0 && <Redirect to={`/taxonomies/${counterArray}/edit`} />}
            <Breadcrumbs text="Taxonomies / Create" />
            <h1 className="h1 lg:w-8/12 mt-10">Create new options set</h1>
            <div className="w-11/12 lg:w-1/2 m-auto">
                <Error
                    status={taxos.taxoErrStatus}
                    errors={taxos.taxoErrors}
                    close="TAXO_ERR_CLOSE"
                />
                <Success
                    status={taxos.taxoSuccess}
                    message="Operation completed successfully."
                    close="TAXO_SUC_CLOSE"
                />
            </div>

            <form
                action=""
                onSubmit={handleSubmit}
                className="form lg:w-4/12 animate__animated animate__fadeIn animate__faster"
                autoComplete="off"
            >
                <div className="flex flex-col">
                    <label htmlFor="" className="mr-2 w-full label">
                        Name:
                    </label>
                    <input
                        type="text"
                        className="input w-full"
                        name="name"
                        onChange={handleChange}
                        value={data.name}
                    />
                </div>
                <div className="flex flex-col mt-5">
                    <label htmlFor="" className="mr-2 w-full label">
                        Display Name:
                    </label>
                    <input
                        type="text"
                        className="input w-full"
                        name="display_name"
                        onChange={handleChange}
                        value={data.display_name}
                    />
                </div>
                <div className="flex flex-col mt-5">
                    <label htmlFor="" className="mr-2 w-full label">
                        Type:
                    </label>
                    <select
                        name="type"
                        id=""
                        className="select"
                        onChange={handleChange}
                        value={data.type}
                    >
                        <option value="radio_buttons">Radio Buttons</option>
                        <option value="swatch">Swatch</option>
                        <option value="rectangles">Rectangles</option>
                        <option value="dropdown">Dropdown</option>
                    </select>
                </div>
                <div className="flex justify-center mt-5">
                    <CancelBtn
                        text="Cancel"
                        icon="fa fa-arrow-left"
                        url="/taxonomies"
                    />
                    <Submit
                        status={taxos.taxoBtn}
                        text="Create and Set Options"
                        icon="fa fa-save"
                    />
                </div>
            </form>
        </div>
    );
};

export default TaxoCreate;
