import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getTaxonomyValues, openModal, TaxoValUpdateAction } from '../../redux/actions/taxonomyValueA'
import Loading from '../components/Loading'
import NoRecords from '../components/NoRecords'
import TaxoValDelete from './TaxoValDelete';

const TaxoValList = ( {taxonomyId} ) => {
    const taxoval = useSelector( state => state.taxonomyValueR );
    const dispatch = useDispatch();

    useEffect( () => {
        dispatch(getTaxonomyValues(taxonomyId))
    },[dispatch, taxoval.taxovalGet, taxonomyId])

    const handleModal = (event) => {
        dispatch(openModal(event.target.getAttribute('data-id')))
    }

    const handleChangeDefault = (event) => {
        dispatch(TaxoValUpdateAction(event.target.getAttribute('data-id')))
    }   

    return (
        <Fragment>
            {taxoval.taxovalLoad ? <Loading /> : (
                <Fragment>
                    {taxoval.taxonomyValues.length === 0 ? <NoRecords /> : (
                        <table className="table mt-5">
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Value</th>
                                    <th>Default</th>
                                    <th>Option</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(taxoval.taxonomyValues).map( ([key,item]) => 
                                    <tr key={key}>
                                        <td>{item.label}</td>
                                        <td>{item.value_data}</td>
                                        {/* eslint-disable-next-line */}
                                        <td>{ item.is_default == true ? <i className="fa fa-check text-green-700"></i> : <i className="fa fa-times text-red-700"></i>  }</td>
                                        <td data-id={item.id} >
                                            <button className="btnDelete" data-id={item.id} onClick={handleModal}>
                                                <i className="fa fa-trash" data-id={item.id} ></i>
                                            </button>
                                            {/* eslint-disable-next-line */}
                                            {item.is_default == false && <button onClick={handleChangeDefault} className="text-blue-400" data-id={item.id}>
                                                <i className="fa fa-check"></i> Set Default
                                            </button>}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    )}
                </Fragment>
            )}
            <TaxoValDelete />
        </Fragment>
    )
}

export default TaxoValList