import React, { useState, Fragment } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/Loading";
import { getCategories } from "../../redux/actions/categoryA";
import { getMixValues, createMix } from "../../redux/actions/mixOutfitBuilderA";

const MixOutFitBuilder = () => {
    const prod = useSelector((state) => state.productR);
    const cat = useSelector((state) => state.categoryR);
    const mix = useSelector((state) => state.mixOutfitBuilderR);
    const dispatch = useDispatch();
    const [arreglo, setArreglo] = useState({});

    useEffect(() => {
        if (!cat.catGet) {
            dispatch(getCategories());
        }
    }, [dispatch, cat.catGet]);

    useEffect(() => {
        if (prod.product.id) {
            dispatch(getMixValues(prod.product.id));
        }
    }, [dispatch, prod.product.id]);

    useEffect(() => {
        if (arreglo.length > 0) {
            // setTimeout(()=>{
            Object.values(mix.selected).map((item) => {
                document.getElementById(`id_${item.id}`).checked = true;
            });
            // }, 1000)
        }
    }, [mix.selected, arreglo]);

    useEffect(() => {
        if (prod.product.categories !== "undefined") {
            var element = prod.product.categories;
            if (element && element.length > 0) {
                var lista = cat.categories.filter(
                    (item) => item.bc_category_id !== element[0]
                );
                setArreglo(lista);
            }
        }
    }, [prod.product.categories, cat.categories]);

    const handleChange = (event) => {
        let element = event.target;
        let checked = element.checked;
        let valor = element.value;
        let data = {
            status: checked,
            product_id: prod.product.id,
            category_id: valor,
        };
        dispatch(createMix(data));
    };
    // console.log(arreglo);
    return (
        <div className="animate__animated animate__fadeIn animate__faster w-full px-10 m-auto">
            <h1 className="h1">Combiner Outfit Builder</h1>
            {prod.product.name ? (
                <div className="w-full animate__animated animate__fadeIn animate__faster mt-5">
                    <div className="border-b border-gray-200 text-lg font-bold">
                        {prod.product.name}
                    </div>
                    {cat.catLoad ? (
                        <Loading />
                    ) : (
                        <Fragment>
                            <table className="table w-full mt-5">
                                <thead>
                                    <tr>
                                        <td colSpan="3">
                                            Select category to combine with this
                                            product
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>...</th>
                                        <th>Name</th>
                                        <th>Created at</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {arreglo.length === 0 ? (
                                        <tr>
                                            <td colSpan="3">No categories</td>
                                        </tr>
                                    ) : (
                                        Object.values(arreglo).map((item) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        className="w-5 h-5"
                                                        id={`id_${item.id}`}
                                                        onChange={handleChange}
                                                        value={item.id}
                                                    />
                                                </td>
                                                <td>
                                                    <label
                                                        className="block cursor-pointer"
                                                        htmlFor={`id_${item.id}`}
                                                    >
                                                        {item.name}
                                                    </label>
                                                </td>
                                                <td>
                                                    <label
                                                        className="block cursor-pointer"
                                                        htmlFor={`id_${item.id}`}
                                                    >
                                                        {item.created_at}
                                                    </label>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </Fragment>
                    )}
                </div>
            ) : (
                <Loading />
            )}
        </div>
    );
};

export default MixOutFitBuilder;
