import api from "../../api";

export const getTaxonomyValues = (taxonomy_id) => async (dispatch) => {
    dispatch({ type: "TAXOVAL_LOAD" });
    try {
        const response = await api.get(`taxonomy-values/${taxonomy_id}`);
        if (response.status === 200) {
            dispatch({
                type: "TAXOVAL_GET_ALL",
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
};

export const TaxoValueStoreAction = (data) => async (dispatch) => {
    dispatch({ type: "TAXOVAL_BTN" });
    try {
        const response = await api.post("taxonomy-values", data);
        console.log(response);
        dispatch({
            type: "TAXOVAL_ADD",
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: "TAXOVAL_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const TaxoValUpdateAction = (data) => async (dispatch) => {
    dispatch({ type: "TAXOVAL_BTN" });
    try {
        const response = await api.put(`taxonomy-values/${data}`);
        dispatch({
            type: "TAXOVAL_EDIT",
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: "TAXOVAL_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const TaxoValDeleteAction = (data) => async (dispatch) => {
    dispatch({ type: "TAXOVAL_BTN" });
    try {
        await api.delete(`taxonomy-values/${data}`);
        dispatch({
            type: "TAXOVAL_REMOVE",
            payload: data
        });
    } catch (error) {
        dispatch({
            type: "TAXOVAL_ERR",
            payload: error.response.data.errors,
        });
    }
};

export const openModal = (id) => {
    return {
        type: 'TAXOVAL_MODAL',
        payload: id
    }
}