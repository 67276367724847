import React from 'react';

const DashBoard = () => {
    return (
        <div>
            <h1 className="dashboardHero col-span-3">Welcome to the NuEthic Outfit Builder</h1>
            <br></br>
            <h2 className="frontPage supportH2">Create different attributes</h2>
            <br></br>
            <div className="frontPage supportH2">Build infinite combinations of products</div>
        </div>

    )
}

export default DashBoard;